<template>
    <div class="container">
        <div class="search" style="display: flex">
            <el-form ref="searchform" style="flex: 1" :inline="true" :model="search" class="demo-form-inline">
                <el-form-item>
                    <el-input @keyup.enter="Search('condition')" v-model.trim="search.uid"
                        placeholder="用户标识"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select clearable v-model="search.message_code" placeholder="模版" @change="Search('condition')">
                        <el-option v-for="item in templateList" :key="item.code" :label="item.name" :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input @keyup.enter="Search('condition')" v-model.trim="search.task_id"
                        placeholder="任务id"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker v-model="date1" :clearable="false" @blur="focusEvent1" type="datetime"
                        placeholder="开始时间" :shortcuts="shortcuts" @change="selectTime">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-date-picker v-model="date2" :clearable="false" @blur="focusEvent2" type="datetime"
                        placeholder="结束时间" :shortcuts="shortcuts" @change="selectTime1">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="Search('condition')">查询</el-button>
                </el-form-item>
            </el-form>
            <!-- <div>
        <el-button type="primary" size="small" @click="addSysmessage">新建</el-button>
      </div> -->
        </div>
        <!-- 列表 -->
        <el-table border v-bind:data="list" :empty-text="emptyText">
            <el-table-column label="任务id" width="100px">
                <template v-slot="scope">
                    <span>{{ scope.row.task_id }}</span>
                </template>
            </el-table-column>
            <el-table-column label="uid" width="200px">
                <template v-slot="scope">
                    <span>{{ scope.row.uid }}</span>
                </template>
            </el-table-column>
            <el-table-column label="消息名称">
                <template v-slot="scope">
                    <router-link style="color:blue;cursor: pointer;" :to="'/message/templatemessageadd/' +
                        scope.row.message_code
                        ">
                        <span>{{ scope.row.message_name }}</span>
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column label="是否已读" width="80px">
                <template v-slot="scope">
                    <span>{{
                        scope.row.is_read == 0
                            ? '未读'
                            : scope.row.is_read == 1
                                ? '已读'
                                : '未知'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="220px">
                <template v-slot="scope">
                    <span>{{ changeTime(scope.row.create_time) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="查看时间" width="220px">
                <template v-slot="scope">
                    <span>{{ changeTime(scope.row.read_time) }}</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="page">
            <el-tag size="small" style="margin-right: 4px;">共 {{ total }} 条</el-tag>
            <el-button type="text" :disabled="predisabled" @click="prePage" size="mini">上一页</el-button>
            <el-button type="text" :disabled="nextdisabled" @click="nextPage" size="mini">下一页</el-button>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
import util from "../../../util/util"
export default {
    name: "actmessage",
    mixins: [],
    components: {},
    props: {},
    data() {
        return {
            shortcuts: [
                {
                    text: "今天",
                    value: new Date(),
                },
            ],
            search: {
                task_id: '',
                action: 2,
                last_id: "",
                start_time: "",
                end_time: "",
                message_code: "",
                uid: "",
                rows: 10,
            },
            list: [],
            total: 0,
            predisabled: false,
            nextdisabled: false,
            templateList: [],
            date1: "",
            date2: "",
            emptyText: "请按条件查询",
        }
    },
    computed: {},
    watch: {},
    methods: {
        focusEvent1() {
            if (!this.date1) {
                this.search.start_time = ""
            }
        },
        focusEvent2() {
            if (!this.date2) {
                this.search.end_time = ""
            }
        },
        selectTime() {
            if (this.date1) {
                if (this.search.start_time == this.date1.getTime() / 1000) {
                    return
                }
                this.search.start_time = this.date1.getTime() / 1000
            } else {
                this.search.start_time = ""
            }
        },
        selectTime1() {
            if (this.date2) {
                if (this.search.end_time == this.date2.getTime() / 1000) {
                    return
                }
                this.search.end_time = this.date2.getTime() / 1000
            } else {
                this.search.end_time = ""
            }
        },
        changeTime(time) {
            return util.dateFormat(time)
        },
        prePage() {
            this.search.action = 1
            this.search.last_id = this.list[0].id
            this.Search("page")
        },
        nextPage() {
            this.search.action = 2
            this.search.last_id = this.list[this.list.length - 1].id
            this.Search("page")
        },
        getTemplate() {
            this.api
                .TemplateList({
                    page: 1,
                    limit: 1000,
                    kname: "",
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.templateList = res.data.data.data
                        return
                    }
                    this.$message.error("获取失败" + res.data.msg)
                })
        },
        Search(item) {
            if (
                !this.search.start_time &&
                !this.search.end_time &&
                !this.search.message_code &&
                !this.search.uid
            ) {
                this.$message.error("请输入查询条件!")
                return
            }
            if (item == "condition") {
                this.predisabled = false
                this.nextdisabled = false
                this.search.last_id = ""
            }
            this.api
                .ActMessageList({
                    task_id: this.search.task_id,
                    action: this.search.action,
                    last_id: this.search.last_id,
                    start_time: this.search.start_time,
                    end_time: this.search.end_time,
                    message_code: this.search.message_code,
                    uid: this.search.uid,
                    rows: this.search.rows,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        if (res.data.data.data && res.data.data.data.length) {
                            if (this.search.action == 1) {
                                this.nextdisabled = false
                            } else {
                                this.predisabled = false
                            }
                            this.list = res.data.data.data
                            this.total = res.data.data.count
                        } else if (
                            item == "condition" &&
                            ((res.data.data.data && !res.data.data.data.length) ||
                                !res.data.data.data)
                        ) {
                            this.list = []
                            this.emptyText = "No Data"
                            this.predisabled = true
                            this.nextdisabled = true
                        } else if (
                            item == "page" &&
                            ((res.data.data.data && !res.data.data.data.length) ||
                                !res.data.data.data)
                        ) {
                            if (this.search.action == 1) {
                                this.predisabled = true
                            } else {
                                this.nextdisabled = true
                            }
                        }
                        if (res.data.data.data && res.data.data.data.length == 0) {
                            this.emptyText = "No Data"
                        }
                        // this.list = res.data.data.data
                        // this.total = res.data.data.page_info.total
                        return
                    }
                    this.emptyText = "No Data"
                    this.$message.error("获取失败" + res.data.msg)
                })
        },
    },
    filters: {},
    mounted() {
    },
    created() {
        this.getTemplate()
    },
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.page {
  float: right;
  margin-top: 10px;
}
</style>
